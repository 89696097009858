import { useEffect, useState } from "react";

export default function useDeviceDetect() {
    const [isMobile, setMobile] = useState<boolean>(false);
    const [isLandscape, setLandscape] = useState<boolean>(false);
    const [isOnline, setOnline] = useState<boolean>(false);
    const [isScrolled, setScrolled] = useState<{scrolled: boolean, offset: number}>({scrolled: false, offset: 0});
    const check = (): void => {
        const userAgent =
            typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        const mobile = Boolean(
            userAgent.match(
                /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
            )
        );
        setLandscape(window.innerHeight < window.innerWidth);
        setMobile(mobile);
        setScrolled({
            scrolled: (window as any).offset > 0,
            offset: (window as any).offset
        });
    };
    
    useEffect(() => {
        window.addEventListener('DOMContentLoaded', check);
        window.addEventListener('resize', check); 
        window.addEventListener('scroll', check);

        window.addEventListener('online', () => setOnline(true));
        window.addEventListener('offline', () => setOnline(false));
        
    }, []);

    return { isMobile, isLandscape, isOnline, isScrolled };
}
