import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderImage from '../HeaderImage';

import { Props, State, Services } from './PropState.interface';
import Styles from './Afspraken.module.scss';
import Header from '../../Assets/Images/afspraken.jpg';
import Praktijk from '../../Assets/Images/praktijk.jpeg';

const AfsprakenContainer: FC<Props> = (props: Props) => {
    document.title = "Kristal Health - Afspraken & Contact";
    const history = useHistory<State>();
    const [selectedService] = useState<Services>(history.location.state?.selectedService || Services.ONBEKEND);
    const swapService: Function = (index: number = selectedService): string => {
        return Services[index];
    }
    return (
        <section className={Styles.Host} onScroll={(e: React.UIEvent<HTMLElement, UIEvent>) => props.scrollFunction(e)}>
            <HeaderImage
                source={Header}
                alt="Header"
                overlay="Afspraken &amp; Contact"
            />
            <section className={Styles.horizontalRow}>
                <article style={{flexBasis: 40}}>
                    <title>
                        Kristal-Health
                    </title>
                    <address className={Styles.address}>
                        <p className={Styles.name}>
                            Kris Jacobs
                        </p>
                        <p className={Styles.location}>
                            Lotelinglaan 33<br />
                            2980 Halle-Zoersel
                        </p>
                        <a href={String().concat("mailto:", "kris@kristal-health.be", "?SUBJECT=Afspraak ", swapService())}>kris@kristal-health.be</a>
                        <a href="tel:+32473462054">0473 46 20 54</a>
                    </address>
                </article>
                <aside style={{ flexBasis: 60 }} className={Styles.map}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d718.7670148654241!2d4.644992134711923!3d51.24123920567137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3ff93afcdc4e1%3A0x48f31ad4789beb1a!2sLotelinglaan%2033%2C%202980%20Zoersel!5e1!3m2!1snl!2sbe!4v1602872962949!5m2!1snl!2sbe"
                        aria-hidden="false"
                        title="Kaart">
                    </iframe>
                </aside>
            </section>
            <section className={Styles.horizontalRow}>
                <img src={Praktijk} alt="Sfeerbeeld praktijk" title="Praktijk"  className={Styles['responsive-image']} />
            </section>
        </section>
    )
}

export default AfsprakenContainer;