import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Props } from './Props.interface';
import Styles from './Home.module.scss';
import { Services } from '../Afspraken/PropState.interface';


import Kris62 from '../../Assets/Images/Kris62.jpg';
import Meditatie from '../../Assets/Images/foto-meisje-meditatie.jpg';


import HeaderXS from '../../Assets/Images/Kristal_home_478px.jpg';
import HeaderMD from '../../Assets/Images/Kristal_home_780px.jpg';
import HeaderLG from '../../Assets/Images/Kristal_home_1024px.jpg';

export enum SIZES {
    xs = 468,
    md = 768,
    lg = 1024
}

const HomeContainer: FC<Props> = (props: Props) => {
    document.title = "Kristal Health";
    const history = useHistory();
    return (
        <section className={Styles.Host} onScroll={(e: React.UIEvent<HTMLElement, UIEvent>) => props.scrollFunction(e)}>
            <picture>
                <source srcSet={HeaderLG} media={`(min-width: ${SIZES.lg}px)`}></source>
                <source srcSet={HeaderMD} media={`(min-width: ${SIZES.md}px)`}></source>
                <img className={Styles.homeImage} src={HeaderXS} alt="Header afbeelding" title="intro Kristal Health" />
            </picture>
            <section>
                Een goede gezondheid willen we allemaal, maar soms halen  aanslepende vage klachten,  je fysiek en mentaal onderuit.  Je lichaam is je tempel, zorg er goed voor!  Ik kan je helpen de juiste balans terug te vinden! Wil je meer weten, zie hieronder - of ga rechtstreeks naar
                <button type={"button"} onClick={() => history.push('/afspraken', { selectedService: Services.ONBEKEND})}>
                    Boek je afspraak nu
                </button>
            </section>
            <section className={Styles.horizontalRow}>
                <article>
                    <title id="wellness">
                        Wat mag je verwachten van een behandeling bij  Kristal-Health &amp; Wellness?
                    </title>
                    <p>
                        <span>
                            Voor preventie en verlichting van o.a. volgende klachten, kan je kiezen voor een <a href="#tuina" target="_self" title="Ontdek tuina therapie" hrefLang="nl_BE">TuiNa therapie</a>
                        </span>
                        <span>
                            Chronische en acute pijnklachten, artrose en artritis,  rug- en schouderproblemen, stijve nek, tennis- en golfelleboog…  stress, hoge bloeddruk, CVS en fibromyalgie, slaapproblemen, migraine en spanningshoofdpijn, enz..
                        </span>
                        <span>
                            <b>Baby’s en jonge kinderen</b> reageren heel goed op deze therapie : krampjes,  hoest en snotneuzen, rusteloze kindjes die niet willen slapen…  met een aantal eenvoudige en lichte massagetechnieken  kunnen deze klachten snel verbeteren.  Ik leer deze ook aan de jonge moeders, om thuis verder toe te passen.
                        </span>
                    </p>
                    <p>
                        <span>
                            Of je kan kiezen voor een algemene <b>ontspannende massage</b>. Kom heerlijk tot rust, en laad je batterijen op met een van de verschillende mogelijke technieken.
                            Alle behandelingen worden gedaan met in acht neming van de nodige veiligheid en respect.
                        </span>
                    </p>  
                    <strong>
                            Een goede energiebalans zorgt voor een gezonde geest in een gezond lichaam !
                    </strong>
                    <button type={'button'} onClick={() => history.push('/diensten')}>
                        Diensten en prijzen
                    </button>
                    <button type={"button"} onClick={() => history.push('/afspraken', { selectedService: Services.ONBEKEND })}>
                        Boek je afspraak nu
                    </button>
                </article>
                <aside>
                    <img src={Kris62} alt="Filler" title="Kris" className={Styles["responsive-image"]} />
                </aside>
            </section>
            <section className={Styles.horizontalRow}>
                <aside className="hideOnMobile">
                    <img src={Meditatie} alt="Filler" title="Meditatoe meisje" className={Styles["responsive-image"]} />
                </aside>
                <article>
                    <title id="tuina">
                        Wat is TuiNa en wat mag je verwachten van een TuiNa therapie sessie?
                    </title>
                    <span>
                        TuiNa is een therapeutische massage volgens de traditionele Chinese geneeskunde, zoals acupunctuur, Qi Gong, Tai chi en Chinese kruidentherapie.
                        Het basisprincipe ervan ligt heel dicht bij dit van acupunctuur:  de lichaamsenergie laten stromen door druk uit te oefenen op de acupunctuurpunten en meridianen.  Mogelijke blokkades van de energiestromen worden opgeheven door gerichte massage van spierweefsel en onderliggende bloed- en zenuwbanen. Je lichaam en geest recupereren. 
                    </span>
                    <span>
                        Tijdens een eerste sessie nemen we tijd voor een intakegesprek waarin we je klachten overlopen. Ik neem je pols en bekijk je tong. Op basis hiervan stel ik een persoonlijk behandelingsplan op en krijg je een eerste weldoende therapie.
                        Een TuiNa therapie sessie maakt gebruik van een combinatie van technieken. De drukpunt- en spier-massage is niet pijnlijk, maar is niet te vergelijken met een ontspanningsmassage.
                    </span>
                    <span>
                        Bij zeer gespannen spieren kan ik bijkomend cups gebruiken.  In bepaalde gevallen is het gebruik van moxa aangewezen. 
                        TuiNa kan uitgevoerd worden op je kleding (doe makkelijke losse kleding aan), of op de huid met een doek bovenop, volgens je voorkeur. 
                    </span>                        
                </article>
            </section>
            <h3>
                YIN &amp; YANG ALS BASIS VOOR JOUW BALANS OP FYSIEK, EMOTIONEEL EN SPIRITUEEL NIVEAU !
            </h3>
        </section>
    )
};

export default HomeContainer;