import React, { FC } from 'react';

import { Props } from './Props.interface';
import Styles from './OverMij.module.scss';
import './OverMij.module.scss';
import HeaderImage from '../HeaderImage';

import Header from '../../Assets/Images/home.jpg';

import Kris from '../../Assets/Images/kris_circleCropped.png';
import Kris126 from '../../Assets/Images/Kris126.jpg';

const OverMijContainer: FC<Props> = (props: Props) => {
    document.title = "Kristal Health - Kennismaking met Kris";
    return (
        <section className={Styles.Host} onScroll={(e: React.UIEvent<HTMLElement, UIEvent>) => props.scrollFunction(e)}>
            <HeaderImage
                source={Header}
                alt="Header"
                overlay="Kennismaking met Kris"
            />
            <section className={Styles.portretContainer}>
                <aside className={Styles.portret}>
                    <img src={Kris} alt="Portret Kris" className={Styles["responsive-image"]} />
                </aside>
                <article>
                    <title>
                        Je bent in goede handen bij mij, <small>dankzij de onderstaande relevante opleidingen en ervaring : </small>
                    </title>
                    <i></i>
                    <ul>
                        <li>
                            Diploma ‘Herborist’ aan het CMO te Antwerpen (juni 2002)
                        </li>
                        <li>
                            Jaartraining : “Voetreflexologie” bij ‘Natuurlijk Genezen’ (juni 2003)
                        </li>
                        <li>
                            Diploma ‘ Massagetherapeut’ bij de Belgische Massage Federatie vzw (april 2007)
                        </li>
                        <li>
                            Verdiepingscursussen ‘Aromatherapie’ bij Wellness Academie (juni 2007)
                        </li>
                        <li>
                            Organisator van diverse workshops ‘natuurlijke remedies’, en holistisch massage therapeut in de Zinzolder te Halle-Zoersel (2008-2010)
                        </li>
                        <li>
                            Qigong stage in Beijing (april 2018)
                        </li>
                        <li>
                            Post-graduaat ‘Massage en bewegingsleer’ Traditionele Chinese Geneeskunde bij OTCG, uitgereikt door de Karel de Grote Hogeschool (2016-2020)
                        </li>
                    </ul>
                    <section className={Styles.horizontalRow}>
                        <article>
                            <title>
                                Het verhaal van : ‘Kristal Health &amp; Wellness’
                            </title>
                            <p>
                                heb ik te danken aan een hele lieve collega…Ik was op zoek naar een naam voor mijn nieuwe praktijk.  Zij gaf me op een blauwe maandag een lief klein pakje, met de vermelding : “start nu maar met je praktijk”! 
                                In het pakje zat een prachtige kleine kristal, het bezorgde me kippenvel !
                                De boodschap was ‘kris-tal-helder’ en duidelijk!  
                            </p>
                            <h3>
                                Met alle opgedane kennis en ervaring, kan ik jouw gezondheid in de goede richting helpen!
                            </h3>
                        </article>
                        <aside style={{ borderRadius: '2rem', overflow: 'hidden' }}>
                            <img src={Kris126} alt="Filler" title="Kris" className={Styles["responsive-image"]} />
                        </aside>
                    </section>
                </article>
            </section>
        </section>
    )
};

export default OverMijContainer;