export enum Services{
    ONBEKEND = 0,
    TUINA = 1,
    ONTSPANNENDE_MASSAGE = 2
}
export interface Props{ 
    scrollFunction: Function,
    location?: Location
}
export interface State{ 
    selectedService: Services
}