import React, { useEffect, ComponentType } from 'react';
import { useHistory } from 'react-router-dom';

const withUseHistory = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => ({ ...props
}: P) => {
    const history = useHistory();
    useEffect(() => {
        return history.listen((any) => {
            const { hash } = window.location;
            if (hash !== '') {
                setTimeout(() => {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) element.scrollIntoView();
                }, 0);
            }
            const scroll = new CustomEvent("scroll");
            (window as any).offset = 0;
            window.dispatchEvent(scroll);
        })
    }, [history]);
    return <Component {...props as P} />
};

export default withUseHistory;