import React, { FC, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Props } from './PropState.interface';

import Styles from './Menu.module.scss';
import useDeviceDetect from '../../utils/DeviceDetect';

import HeaderXS from '../../Assets/Logo/kristal_logo-xs.png';
import HeaderMD from '../../Assets/Logo/kristal_logo-md.png';
import HeaderLG from '../../Assets/Logo/kristal_logo-lg.png';

export enum SIZES{
    xs = 468,
    md = 768,
    lg = 1024
}

const Menu: FC<Props> = (props: Props) => {
    const { isScrolled, isMobile } = useDeviceDetect();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const Routes: FC<{}> = () => {
        return (
            <Fragment>
                <NavLink
                    to={{ pathname: '/' }}
                    onClick={() => setMobileMenuOpen(false)}
                    exact={true}
                    activeClassName={Styles.active}
                    className={Styles.link}>
                        Home
                </NavLink>
                <NavLink
                    to={{ pathname: '/', hash: '#wellness' }}
                    onClick={() => setMobileMenuOpen(false)}
                    exact={true}
                    className={[Styles.link, Styles.hideOnDesktop].join(' ')}>
                        Wat mag je van een behandeling bij Kristal-health &amp; Wellness?
                </NavLink>
                <NavLink
                    to={{ pathname: '/', hash: '#tuina' }}
                    onClick={() => setMobileMenuOpen(false)}
                    exact={true}
                    className={[Styles.link, Styles.hideOnDesktop].join(' ')}>
                        Wat is TuiNa en wat mag je verwachten van een Tuina therapie sessie?
                </NavLink>
                <NavLink
                    to={{ pathname: '/diensten', hash: null }}
                    onClick={() => setMobileMenuOpen(false)}
                    activeClassName={Styles.active}
                    className={Styles.link}>
                        Diensten &amp; prijslijst
                </NavLink>
                <NavLink
                    to={{ pathname: '/afspraken', hash: null }}
                    onClick={() => setMobileMenuOpen(false)}
                    activeClassName={Styles.active}
                    className={Styles.link}>
                        Afspraken &amp; contact
                </NavLink>
                <NavLink
                    to={{ pathname: '/overmij', hash: null }}
                    onClick={() => setMobileMenuOpen(false)}
                    activeClassName={Styles.active} className={Styles.link}>
                        Over mij <span className={Styles.hideOnDesktop}> - kennismaking met Kris</span>
                </NavLink>
            </Fragment>
        )
    };
    return (
        <nav className={[Styles.Host, isScrolled.scrolled ? Styles.scrolled : '' ].join(' ')}>
            <NavLink to="/" className={Styles.logoContainer}>
                <picture>
                    <source srcSet={HeaderLG} media={`(max-width: ${SIZES.lg}px)`}></source>
                    <source srcSet={HeaderMD} media={`(max-width: ${SIZES.md}px)`}></source>
                    <img src={HeaderXS} alt="Logo Kristal Health" className={Styles.logo} />
                </picture>
            </NavLink>
            {
                isMobile ?
                    <Fragment>
                        <div className={Styles.mobileContainer} onClick={() => setMobileMenuOpen(true)}>
                            <svg viewBox="0 0 100 80">
                                <rect width="100" height="15" rx="5" />
                                <rect y="30" width="100" height="15" rx="5"  />
                                <rect y="60" width="100" height="15" rx="5"  />
                            </svg>
                        </div>
                        {
                            isMobileMenuOpen ? 
                                <nav className={Styles.mobileMenu}>
                                    <div className={Styles.closeContainer} onClick={() => setMobileMenuOpen(false)}>
                                        <svg viewBox="0 0 100 100">
                                            <line width="2" x1="20" y1="20" x2="80" y2="80" />
                                            <line width="2" x1="20" y1="80" x2="80" y2="20" />
                                        </svg>
                                    </div>
                                    <Routes />
                                </nav> : 
                                null
                        }
                    </Fragment> : 
                    <section className={Styles.linkContainer}>
                        {
                            <Routes />
                        }
                    </section>
            }
        </nav>
    )
}
export default Menu