import React, { FC } from 'react';

import { Props } from './PropState.interface';
import Styles from './HeaderImage.module.scss';

const HeaderImage: FC<Props> = ({ source, alt, title = undefined, overlay = [], className = [], ...args }: Props) => {
    const classes: Array<string> = typeof className === "string" ? [className] : className;
    const overlays: Array<string> = typeof overlay === "string" ? [overlay] : overlay
    return (
        <div className={[Styles.HeaderImageContainer].concat(classes).join(' ')}  {...args}>
            {
                overlays.map((x, i) => <span key={i}> {x} </span>)
            }
            <img src={source} alt={alt} title={title || overlays.join(' ')} />
        </div>
    )
};
export default HeaderImage;