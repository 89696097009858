import React, { FC, UIEvent } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import { Props } from './PropState.interface';
import './Container.module.scss';
import Menu from '../Menu';
import HomeContainer from '../Home';
import DienstenContainer from '../Diensten';
import AfsprakenContainer from '../Afspraken';
import OverMijContainer from '../OverMij';
import withUseHistory from '../../HOC/UseHistoryHoc';

export interface CustomScrollEvent extends Event{
    value: number
}

const Container: FC<Props> = (props: Props) => {
    const dispatchScroll: Function = (e: UIEvent<HTMLElement, UIEvent>): void => {
        const scroll = new CustomEvent("scroll");
        (window as any).offset = e.currentTarget.scrollTop;
        window.dispatchEvent(scroll);
    };
    const [HomeWrapper, DienstenWrapper, AfsprakenWrapper, OverMijWrapper] = [HomeContainer, DienstenContainer, AfsprakenContainer, OverMijContainer].map(x => withUseHistory(x))
    return (
        <BrowserRouter>
            <Menu />
            <Switch>
                <Route path="/" exact>
                    <HomeWrapper scrollFunction={dispatchScroll} />
                </Route>
                <Route path="/diensten">
                    <DienstenWrapper scrollFunction={dispatchScroll} />
                </Route>
                <Route path="/afspraken">
                    <AfsprakenWrapper scrollFunction={dispatchScroll} />
                </Route>
                <Route path="/overmij">
                    <OverMijWrapper scrollFunction={dispatchScroll} />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}
export default Container