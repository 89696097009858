import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import HeaderImage from '../HeaderImage';
import { Props } from './Props.interface';
import Header from '../../Assets/Images/diensten.jpg';
import Styles from './Diensten.module.scss';
import { Services } from '../Afspraken/PropState.interface';

import Baby from '../../Assets/Images/baby-noemie.jpg';
import Kris102 from '../../Assets/Images/Kris102.jpg';

const DienstenContainer: FC<Props> = (props: Props) => {
    document.title = "Kristal Health - Diensten";
    const history = useHistory();
    return (
        <section className={Styles.Host} onScroll={(e: React.UIEvent<HTMLElement, UIEvent>) => props.scrollFunction(e)}>
            <HeaderImage
                source={Header}
                alt="Header"
                overlay="Diensten &amp; prijslijst"
            />
            <section className={[Styles.ServiceListContainer, Styles.horizontalRow].join(' ')}>
                <article>
                    <title>
                        TuiNa therapie <small>– therapeutische massage volgens de traditionele Chinese geneeskunde</small>
                    </title>
                    <span>
                        Graag meebrengen : 2 grote handdoeken, mondmasker, doe makkelijke loszittende kleding aan.
                        <ul>
                            <li>
                                <div>
                                    Intake en 1e behandeling  &#126;75 min
                                <span>
                                        &euro;55
                                </span>
                                </div>
                                <ul>
                                    <li>
                                        We overlopen je klachten en stellen een persoonlijk behandelplan op
                                </li>
                                    <li>
                                        Je krijgt een eerste weldoende therapie
                                </li>
                                </ul>
                            </li>
                            <li>
                                <div>
                                    Volgende behandelingen  &#126;50 min
                                <span>
                                        &euro;45
                                </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    Baby’s en kinderen onder 7 jaar
                            </div>
                                <ul>
                                    <li>
                                        <div>
                                            Intake en 1e behandeling  &#126;45 min
                                        <span>
                                                &euro;40
                                        </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Volgende behandelingen &#126;25 min
                                        <span>
                                                &euro;25
                                        </span>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </span>
                    <button className={Styles.buttonLeft} onClick={() => history.push('/afspraken', { selectedService: Services.TUINA })}>
                        Maak een afspraak voor TuiNa therapie!
                    </button>
                </article>
                <aside>
                    <img src={Baby} alt="Service" title="Baby Noemie" className={Styles["responsive-image"]} />
                </aside>
            </section>
            <section className={[Styles.ServiceListContainer, Styles.horizontalRow].join(' ')}>
                <aside className="hideOnMobile">
                    <img src={Kris102} alt="Filler" title="Kris" className={Styles["responsive-image"]} />
                </aside>
                <article>
                    <title>
                        Ontspannende massage
                </title>
                    <ul>
                        <li>
                            <div>
                                Heerlijk ontspannende massage van het ganse lichaam &#126;60 min
                            <span>
                                    &euro;50
                            </span>
                            </div>
                            <ul>
                                <li>
                                    <div>
                                        met warme olie en aromatherapie &#126;60 min
                                    <span>
                                            &euro;60
                                    </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        Hotstone – pure ontspanning, de warmte dringt diep in je lichaam &#126;75 min
                                    <span>
                                            &euro;75
                                    </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        Kruidenstempel – geurige pakketjes in warme olie, volledige spierontspanning &#126;75 min
                                    <span>
                                            &euro;75
                                    </span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                Voetmassage – met scrub en warm voetbad &#126;30 min
                            <span>
                                    &euro;30
                            </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                Stoelmassage – nek, schouder, armen en hoofd &#126;30 min
                            <span>
                                    &euro;30
                            </span>
                            </div>
                            <small>
                                (gekleed) een snelle opkikker, even alle spanning weg
                        </small>
                        </li>
                    </ul>
                    <button className={Styles.buttonLeft} onClick={() => history.push('/afspraken', { selectedService: Services.ONTSPANNENDE_MASSAGE })}>
                        Maak een afspraak voor een ontspannende massage!
                    </button>
                </article>
            </section>
            <section>
            </section>
        </section>
    )
};

export default DienstenContainer;